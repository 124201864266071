import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { store, isOrg, usersSettingsRef } from '@store';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { dialogs, toast } from '../../message';
import { dialog } from '../DataDialog/DataDialog';
import Feedback from '../Feedback/Feedback';
import InnerMain from './InnerMain';
import AuthDialog from '../Auth/AuthDialog';
import { fontStyle } from '../SnippetEditor/fontConfig';
import { Helmet } from 'react-helmet-async';
import { deleteTokenCookie, getTokenCookie } from '../../flags';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { StatusNotification } from '../StatusNotification/StatusNotification';
import { storage } from '../../utilities';
import { useTypedSelectorShallowEquals } from '../../hooks';
import { databaseDialogs } from '../Database/database_utilities';
import DesktopNavigation from './DesktopNavigation';
import { sendMessageToClient } from '../../desktop_utilities';

function AiAppInner() {
  const { wasOnboardedWithAiBlaze, optionsLoaded, isNewSignUp } = useTypedSelectorShallowEquals(state => ({
    optionsLoaded: !!state.userState?.options,
    wasOnboardedWithAiBlaze: state.userState?.options?.was_onboarded_with_ai_blaze,
    isNewSignUp: state.userState?.newSignUp

  }));

  useEffect(() => {
    const token = getTokenCookie();
    if (!token) {
      return;
    }
    signInWithCustomToken(getAuth(), token)
      .then(deleteTokenCookie)
      .catch(err => toast('Failed to login. Please try again after sometime. ' + err.message, {
        intent: 'danger'
      }));
  }, []);

  // We did it in the dashboard to avoid separate native code changes in Mac and Windows to detect text boxes.
  useEffect(() => {
    const handleFocusChange = (focus) => (event) => {
      const element = event.target;

      const isEditable = element.isContentEditable || element.tagName === 'INPUT' || element.tagName === 'TEXTAREA';

      if (isEditable) {
        sendMessageToClient({
          type: 'editable-focused',
          data: focus
        });
      }
    };

    const onFocusHandler = handleFocusChange(true);
    const onBlurHandler = handleFocusChange(false);

    document.addEventListener('focusin', onFocusHandler);
    document.addEventListener('focusout', onBlurHandler);

    return () => {
      document.removeEventListener('focusin', onFocusHandler);
      document.removeEventListener('focusout', onBlurHandler);
    };
  }, []);

  // Set the onboarded with AI when first visiting AI Blaze
  useEffect(() => {
    if (!wasOnboardedWithAiBlaze && optionsLoaded) {
      /**
       * Before the extension updates and points to AI Blaze, we want  dashboard.blaze.today to redirect to AI Blaze
       *
       * Existing users of AI Blaze will already have this flag as true
       * For new users signing up via AI Blaze, enable the flag
       *
       * Remove after the extension updates
       * 2024.06.03
       */
      if (isNewSignUp) {
        storage.update(usersSettingsRef, { 'options.is_using_ai_blaze': true }, 'HIDE_AUTOSAVE');
      }

    }
  }, [isNewSignUp, optionsLoaded, wasOnboardedWithAiBlaze]);

  return (
    <>
      <Helmet defer={false}>
        <title>AI Blaze Dashboard</title>
      </Helmet>
      <style>{fontStyle}</style>
      <Switch>
        <Route component={InnerMain} />
      </Switch>

      <DesktopNavigation />
      <AuthDialog />
      <Feedback />
      {dialogs({ store, isOrg })}
      <StatusNotification />
      {databaseDialogs()}
      <ErrorBoundary dialog details="in the data dialog">{dialog}</ErrorBoundary>
    </>
  );
}


export default AiAppInner;
